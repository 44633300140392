<template>
  <div class="screenNotifications">
    <NotificationsLiveCard v-for="notify in notifications" :key="notify._id" :notification="notify" />
  </div>
</template>

<script>
export default {
  components: {
    NotificationsLiveCard: () => import("./NotificationsLiveCard.vue"),
  },
  computed: {
    notifications: function() {
      return this.$store.state.notifications.lives;
    },
  },
};
</script>

<style lang="scss">
.screenNotifications {
  @include Fixed();
  background-color: rgba(0, 0, 0, 0.06);
  z-index: 99;
  pointer-events: none;
  .notificationLiveCard * {
    pointer-events: auto;
  }
}
</style>
